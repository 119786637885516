@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;
	
.featured-cards-block {

	@include mixins.sectionStyles;

	.featured-cards-block__title {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		gap: var(--wp--preset--spacing--40);
	}

	&.contained {

		margin: var(--wp--preset--spacing--60) 0;
		padding: 0;

		*:not(.bnpp-button) {
			color: currentColor;
		}

		.bnpp-container {
			background-color: var(--wp--preset--color--bnpp-green-tint-10);
			border-right: 4px solid var(--wp--preset--color--bnpp-green);
			border-left: 4px solid var(--wp--preset--color--bnpp-green);

			&>div {
				width: 80%;
    			margin: var(--wp--preset--spacing--60) auto;
			}
		}

	}

	.featured-cards-block__list {
		@include mixins.grid(250px);
		margin-top: var(--wp--preset--spacing--40);
	}


}